module.exports = {
  siteTitle: 'Sessions West', // <title>
  manifestName: 'SolidState',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Sessions West',
  subHeading: 'Recording Label in Austin TX',
  // social
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/sessions_west',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/sessionswestrecords/',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/sessionswest',
    },    
  ],
  phone: '000-00000',
  address: '305 Del Sol, Austin, TX',
};
