// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amazon-js": () => import("./../../../src/pages/amazon.js" /* webpackChunkName: "component---src-pages-amazon-js" */),
  "component---src-pages-artists-colorado-river-boys-js": () => import("./../../../src/pages/artists/colorado-river-boys.js" /* webpackChunkName: "component---src-pages-artists-colorado-river-boys-js" */),
  "component---src-pages-artists-hugh-fadal-band-js": () => import("./../../../src/pages/artists/hugh-fadal-band.js" /* webpackChunkName: "component---src-pages-artists-hugh-fadal-band-js" */),
  "component---src-pages-artists-jeff-eljefe-anderson-js": () => import("./../../../src/pages/artists/jeff-eljefe-anderson.js" /* webpackChunkName: "component---src-pages-artists-jeff-eljefe-anderson-js" */),
  "component---src-pages-artists-jesse-cravens-js": () => import("./../../../src/pages/artists/jesse-cravens.js" /* webpackChunkName: "component---src-pages-artists-jesse-cravens-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-soundcloud-js": () => import("./../../../src/pages/soundcloud.js" /* webpackChunkName: "component---src-pages-soundcloud-js" */),
  "component---src-pages-spotify-js": () => import("./../../../src/pages/spotify.js" /* webpackChunkName: "component---src-pages-spotify-js" */),
  "component---src-pages-youtube-js": () => import("./../../../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */)
}

