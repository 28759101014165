import React, { useState } from 'react';
import Nav from './Nav';
import logo from '../assets/images/logo.png';
// import { Link } from 'gatsby';
export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
        <ul className="icons icons-header">
          <li>
            <a
              href="https://open.spotify.com/playlist/6Tl6ZXtAfhMCGSZ2SXxZZd?si=tSQfIqjmQjamlylPlq-bqw"
              className="icon fa-spotify"
            >
              <span className="label">Spotify</span>
            </a>
          </li>
          <li>
            <a
              href="https://soundcloud.com/sessionswest"
              className="icon fa-soundcloud"
            >
              <span className="label">Soundcloud</span>
            </a>
          </li>
          {/* <li>
            <a
              href="https://www.youtube.com/channel/UCbzWEqri_-B2rqjJh_DSA6w"
              className="icon fa-youtube"
            >
              <span className="label">Youtube</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/sessionswest"
              className="icon fa-facebook"
            >
              <span className="label">Facebook</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/sessionswestrecords/"
              className="icon fa-instagram"
            >
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/sessions_west"
              className="icon fa-twitter"
            >
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a
              href="https://music.amazon.com/user-playlists/2431d186191c41a7b27ef00fe03f0e94sune?marketplaceId=ATVPDKIKX0DER&musicTerritory=US"
              className="icon fa-amazon"
            >
              <span className="label">Amazon</span>
            </a>
          </li>
        </ul>

        <h1>
          {/* <Link to="/">Sessions West</Link> */}
          <div className="logo">
            <img src={logo} alt="Sessions West Logo" />
          </div>
        </h1>

        <nav>
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              toggleHeader(!headerOpen);
            }}
            className="menuToggle"
          ></a>
        </nav>
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}
